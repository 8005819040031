/*******************************
         Site Overrides
*******************************/

.email-list-item {
    background: lighten(@cyberPastelPink, 20);
}

.ui.vertical.menu {
    background: @cyberPurple;
    border: none;
    border-radius: 0;
    box-shadow: none;
}