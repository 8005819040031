/*******************************
         Site Overrides
*******************************/

.ui.button.main-menu {
    border: none;
    width: 100%;
    box-shadow: none;
    font-weight: bold;
    font-size: large;
    color: @white;
    background: transparent;
}

@media only screen and (max-width: 1700px) {
    .ui.button.main-menu .menu-button-text {
        display: none;
    }
}