/*******************************
    User Variable Overrides
*******************************/

.email-list-item:hover {
    background: lighten(@cyberPastelPink, 10);
}

.email-list-item .email-preview {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}