/*******************************
         Site Overrides
*******************************/

.email-inbox-table tr {
    height: 720px;
}

.email-inbox-table .email-list {
    width: 400px;
}