/*******************************
        Site Overrides
*******************************/
.ui.message {
        background: @cyberPurple;
}

.comment-section {
  margin-left: 10px;
}

.compose-comment {
  margin-top: 10px;
}

.compose-comment-buttons > button {
  margin-top: 10px;
}

.compose-reply-button {
  margin-top: 10px;
}

.comment-moderator-controls {
  margin-left: 2em;
}