/*******************************
         Site Overrides
*******************************/

.ui.checkbox label, .ui.checkbox + label {
    color: @cyberPurple;
}

.ui.checkbox label:hover, .ui.checkbox + label:hover {
    color: @black;
}