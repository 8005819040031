/*******************************
    User Variable Overrides
*******************************/

.email-preview-container {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
    
    border-style: solid;
    border-width: 4px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-image: linear-gradient(90deg, #00000000, @cyberPastelPink, @cyberPurple) 1;

    color: @cyberPurple;
}