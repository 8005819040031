/*******************************
         Site Overrides
*******************************/

.scheduling-section {
    margin: 5px;
}

.episode-display {
    border: 1px solid #FFF;
    margin: 10px 5px;
    padding: 5px;
}

body {
    background-attachment:fixed;
    background-image: url("../../../images/Website_Background_no_logo-01.jpg");
    background-size: 100%;
}

.react-datetime-picker {
    margin-right: 5px;
}