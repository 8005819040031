/*******************************
         Site Overrides
*******************************/
.ui.segment {
    background: @cyberPurple;
    border-radius: 0;
}

.ui.segment.email-header, .ui.segment.email-body {
    background: lighten(@cyberPastelPink, 20);
    color: @cyberPurple;
    border: none;
    box-shadow: none;
}

.ui.segment.transparent-bg {
    background: none;
}

.ui.segment.no-border {
    border: none;
    box-shadow: none;
}

.ui.segment {
    background-image: linear-gradient(to bottom, @darkBlue, @darkPurple 90%);
    border: none;
    box-shadow: none;
}